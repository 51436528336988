
  import Events from '@/events/Events';
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    filters: {
      truncate(value) {
        if (!value) return '';
        if (value.length <= 400) return value;

        return value.substring(0, 400) + '...';
      },
    },
    props: {
      job: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        imageUrl: process.env.NUXT_ENV_FALLBACK_CARD_IMAGE_STATIC,
      };
    },
    created() {
      if (this.job.image) {
        this.imageUrl = prepareImageUrl(this.job.image.url);
      }
    },
    methods: {
      clickWebhook(jobPostingId, position) {
        this.$emitWithClientData(Events.JobDetailsClicked, {
          jobPostingId,
          type: 'HomepageTopJob',
          position,
        });
      },
    },
  };
