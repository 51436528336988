
  import Events from '@/events/Events';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import ModelItems from '@/mixins/ModelItems';
  import ApplyOnJobPostingForm from '@/mixins/ApplyOnJobPostingForm';
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'UpdateBeforeApplicationForm',
    components: {
      DialogsBaseSection,
      PersonalDataForm: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/PersonalDataForm'
        ),
      SkillsForm: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/SkillsForm'
        ),
      /*PromotionExperiencesForm: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/PromotionExperiencesForm'
        ),*/
      ImagesForm: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/ImagesForm'
        ),
      WorkPreferencesForm: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/WorkPreferencesForm'
        ),
      ApplicationForm: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/ApplicationForm'
        ),
      ThankYouPage: () =>
        import(
          '@/components/candidate/UpdateBeforeApplicationDialog/ThankYouPage'
        ),
    },
    mixins: [ModelItems, ApplyOnJobPostingForm, ScreenSize],
    props: {
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
      comeFrom: {
        type: String,
        required: false,
        default: 'detailPage',
      },
      position: {
        type: String,
        required: false,
        default: 'NULL',
      },
      dialog: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        section: {},
        currentSection: {},
        currentStep: 1,
        applicationFinished: false,
      };
    },
    async fetch() {
      this.section = await this.$cachedStrapi
        .find('registration-before-application-dialog')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
      this.currentSection = {
        title: this.section.personal_data_step_title,
        intro_text: this.section.personal_data_step_intro_text,
        button_text: this.section.personal_data_button_text,
      };
    },
    watch: {
      dialog(val) {
        if (val === true) {
          this.setCurrentSection(this.currentStep);
        }
      },
      currentStep(val) {
        this.setCurrentSection(val);
      },
      applicationFinished(val) {
        if (val) {
          this.currentSection = {};
        }
      },
    },
    mounted() {
      this.$store.dispatch('api/favorite/automaticFavorJob', {
        id: this.job.id,
      });
      this.$emitWithClientData(
        this.comeFrom === 'AfterRegistration'
          ? Events.RegistrationBeforeApplicationStepStarted
          : Events.UpdateAndApplicationStepStarted,
        {
          jobPostingId: this.job.id,
          step: 'PersonalData',
        }
      );
    },
    methods: {
      setCurrentSection(step) {
        if (step == 2) {
          this.$emitWithClientData(
            this.comeFrom === 'AfterRegistration'
              ? Events.RegistrationBeforeApplicationStepStarted
              : Events.UpdateAndApplicationStepStarted,
            {
              jobPostingId: this.job.id,
              step: 'Skills',
            }
          );
          this.currentSection = {
            title: this.section.skills_step_title,
            intro_text: this.section.skills_step_intro_text,
            button_text: this.section.skills_button_text,
          };
        } /*else if (step == 3) {
          this.$emitWithClientData(
            this.comeFrom === 'AfterRegistration'
              ? Events.RegistrationBeforeApplicationStepStarted
              : Events.UpdateAndApplicationStepStarted,
            {
              jobPostingId: this.job.id,
              step: 'PromotionExperiences',
            }
          );
          this.currentSection = {
            title: this.section.promotion_experiences_step_title,
            intro_text: this.section.promotion_experiences_step_intro_text,
            button_text: this.section.promotion_experiences_button_text,
          };
        }*/ else if (step == 3) {
          this.$emitWithClientData(
            this.comeFrom === 'AfterRegistration'
              ? Events.RegistrationBeforeApplicationStepStarted
              : Events.UpdateAndApplicationStepStarted,
            {
              jobPostingId: this.job.id,
              step: 'Images',
            }
          );

          this.currentSection = {
            title: this.section.images_step_title,
            intro_text: this.section.images_step_intro_text,
            button_text: this.section.images_button_text,
          };
        } else if (step == 4 && this.comeFrom != 'AfterRegistration') {
          this.$emitWithClientData(
            this.comeFrom === 'AfterRegistration'
              ? Events.RegistrationBeforeApplicationStepStarted
              : Events.UpdateAndApplicationStepStarted,
            {
              jobPostingId: this.job.id,
              step: 'WorkPreferences',
            }
          );
          this.currentSection = {
            title: this.section.work_preferences_step_title,
            intro_text: this.section.work_preferences_step_intro_text,
            button_text: this.section.work_preferences_button_text,
          };
        } else if (
          (this.comeFrom != 'AfterRegistration' && step == 5) ||
          (this.comeFrom === 'AfterRegistration' && step == 4)
        ) {
          this.$emitWithClientData(
            this.comeFrom === 'AfterRegistration'
              ? Events.RegistrationBeforeApplicationStepStarted
              : Events.UpdateAndApplicationStepStarted,
            {
              jobPostingId: this.job.id,
              step: 'Application',
            }
          );
          this.currentSection = {
            title: this.section.application_step_title,
            intro_text: this.section.application_step_intro_text,
            button_text: this.section.application_button_text,
          };
        } else {
          this.$emitWithClientData(
            this.comeFrom === 'AfterRegistration'
              ? Events.RegistrationBeforeApplicationStepStarted
              : Events.UpdateAndApplicationStepStarted,
            {
              jobPostingId: this.job.id,
              step: 'PersonalData',
            }
          );
          this.currentSection = {
            title: this.section.personal_data_step_title,
            intro_text: this.section.personal_data_step_intro_text,
            button_text: this.section.personal_data_button_text,
          };
        }
      },
      changeStepBack(step, stepName) {
        this.currentStep = step;
        this.$emitWithClientData(
          this.comeFrom === 'AfterRegistration'
            ? Events.RegistrationBeforeApplicationStepBack
            : Events.UpdateAndApplicationStepBack,
          {
            jobPostingId: this.job.id,
            step: stepName,
          }
        );
      },
      finishApplication() {
        this.applicationFinished = true;
        this.$emitWithClientData(Events.ThankYouPageOpened, {
          type: this.job
            ? 'RegistrationBeforeApplication'
            : 'UpdateAndApplication',
        });
      },
      onCloseDialog() {
        let dialogType = '';
        if (this.comeFrom != 'AfterRegistration') {
          switch (this.currentStep) {
            case 1:
              dialogType = 'PersonalData';
              break;
            case 2:
              dialogType = 'Skills';
              break;
            /*case 3:
              dialogType = 'PromotionExperiences';
              break;*/
            case 3:
              dialogType = 'Images';
              break;
            case 4:
              dialogType = 'WorkPreferences';
              break;
            case 5:
              dialogType = 'Application';
              break;
          }
        } else {
          switch (this.currentStep) {
            case 1:
              dialogType = 'PersonalData';
              break;
            case 2:
              dialogType = 'Skills';
              break;
            case 3:
              dialogType = 'Images';
              break;
            case 4:
              dialogType = 'Application';
              break;
          }
        }

        if (
          this.comeFrom === 'AfterRegistration' &&
          !this.applicationFinished
        ) {
          this.$emitWithClientData(
            Events.RegistrationBeforeApplicationStepClosed,
            {
              jobPostingId: this.job.id,
              step: dialogType,
            }
          );
        } else if (
          this.comeFrom != 'AfterRegistration' &&
          !this.applicationFinished
        ) {
          this.$emitWithClientData(Events.UpdateAndApplicationStepClosed, {
            jobPostingId: this.job.id,
            step: dialogType,
          });
        } else if (
          this.comeFrom === 'AfterRegistration' &&
          this.applicationFinished
        ) {
          this.$emitWithClientData(Events.ThankYouPageClosed, {
            type: 'RegistrationBeforeApplication',
          });
        } else if (
          this.comeFrom != 'AfterRegistration' &&
          this.applicationFinished
        ) {
          this.$emitWithClientData(Events.ThankYouPageClosed, {
            type: 'UpdateAndApplication',
          });
        }
        this.$emit('close-dialog');
      },
    },
  };
