
  export default {
    name: 'TopBarButtons',

    props: {
      buttons: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    data() {
      return {};
    },
    computed: {
      topBarButtonsCol() {
        if (this.buttons.length === 0) {
          return false;
        } else return 12 / this.buttons.length;
      },
    },
  };
