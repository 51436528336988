
  import {prepareImageUrl} from '@/helper/ImageHelper';

  export default {
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    props: {
      card: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        imageUrl: process.env.NUXT_ENV_FALLBACK_IMAGE_STATIC,
      };
    },
    created() {
      if (this.card.image) {
        this.imageUrl = prepareImageUrl(this.card.image.url);
      }
    },
  };
