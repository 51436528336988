export default {
  async fetch() {
    this.section = await this.$cachedStrapi
      .find(this.currentForm)
      .catch((e) => {
        if (e.statusCode !== 404) {
          throw e;
        }
      });
    this.organizationLinkText = this.section.checkbox_organization_link;
    let organizationId = '';
    if (this.$cookies.get('organization_parameter')) {
      organizationId = this.$cookies.get('organization_parameter').id;
    } else if (
      this.$route.query.organization_id &&
      this.$route.query.mtm_source &&
      this.$route.query.mtm_medium &&
      this.$route.query.mtm_group
    ) {
      organizationId = this.$route.query.organization_id;
    }
    if (organizationId != '') {
      await this.$store
        .dispatch('api/organization/getOrganization', {
          id: organizationId,
        })
        .then((result) => {
          this.currentOrganization = result;
        });
    }
    if (this.currentOrganization && this.organizationLinkText) {
      this.organizationLinkText = this.organizationLinkText.replace(
        /%company_name/g,
        this.currentOrganization.name
      );
    }
    this.organizationAvailableFunction();
  },
  methods: {
    async organizationAvailableFunction() {
      let cookieOrganizationId = '';
      let searchStatus = {};
      if (this.$cookies.get('organization_parameter')) {
        cookieOrganizationId = this.$cookies.get('organization_parameter').id;
      } else if (
        this.$route.query.organization_id &&
        this.$route.query.mtm_source &&
        this.$route.query.mtm_medium &&
        this.$route.query.mtm_group
      ) {
        cookieOrganizationId = this.$route.query.organization_id;
      }
      if (cookieOrganizationId != '' && this.$auth.user.candidateProfile) {
        searchStatus = await this.$store.dispatch(
          'api/candidateProfile/getPBasisSearchStatus',
          {
            candidate_profile_id: this.$auth.user.candidateProfile.id,
            organization_id: cookieOrganizationId,
          }
        );
      }
      this.organizationAvailable =
        this.job.organization.id === cookieOrganizationId &&
        searchStatus.data === false
          ? true
          : false;
    },
  },
};
