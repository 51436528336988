
  export default {
    props: {
      subline: {
        type: String,
        default: undefined,
      },
      sublineType: {
        type: String,
        default: 'none',
      },
    },
    data() {
      return {
        tag: '',
      };
    },

    created() {
      if (this.sublineType != null && this.sublineType != 'none') {
        this.tag = this.sublineType.split('_')[0];
      }
    },
  };
