
  import {prepareImageUrl} from '@/helper/ImageHelper';
  import ScreenSize from '@/mixins/ScreenSize';

  export default {
    name: 'SectionSignIn',
    components: {
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
      OrganizationLoginAndRegistrationDialog: () =>
        import('@/components/organization/LoginAndRegistrationDialog'),
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey(props) {
      // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
      if (props.loggedIn) {
        return false;
      }

      // Ansonsten generiere einen eindeutigen Cache-Schlüssel
      return 'section-sign-in-' + props.section.id;
    },
    data() {
      return {
        imageUrl: process.env.NUXT_ENV_FALLBACK_CARD_IMAGE_STATIC,
      };
    },
    computed: {
      isVisible() {
        const isCorrectDevice =
          (this.section.device_type === 'desktop' &&
            this.$vuetify.breakpoint.width >=
              this.$vuetify.breakpoint.thresholds.sm) ||
          (this.section.device_type === 'mobile' &&
            this.$vuetify.breakpoint.width <
              this.$vuetify.breakpoint.thresholds.sm) ||
          this.section.device_type === 'all' ||
          this.section.device_type === null;
        let condition = isCorrectDevice && !this.$auth.loggedIn;
        if (this.section.type === 'organization') {
          condition = isCorrectDevice;
        }
        return condition;
      },
    },
    created() {
      const imageUrl = this.section.image
        ? this.$vuetify.breakpoint.width <
            this.$vuetify.breakpoint.thresholds.sm &&
          this.section.image.formats.medium &&
          this.section.image.formats.medium.url
          ? this.section.image.formats.medium &&
            this.section.image.formats.medium.url
          : this.section.image.url
        : false;

      if (imageUrl) {
        this.imageUrl = prepareImageUrl(imageUrl);
      }
    },
  };
