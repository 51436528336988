
  import clearableTabIndex from '@/mixins/clearableTabIndex';

  export default {
    name: 'ForgotPasswordDialog',
    components: {
      RequestPasswordForm: () =>
        import(
          '@/components/candidate/ForgotPasswordDialog/RequestPasswordForm'
        ),
      ResetPasswordForm: () =>
        import('@/components/candidate/ForgotPasswordDialog/ResetPasswordForm'),
    },
    mixins: [clearableTabIndex],
    props: {
      currentPage: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        dialog: false,
        dialogWidth: 900,
        page: null,
      };
    },
    mounted() {
      this.dialog = true;
      this.page = this.currentPage;
    },
    methods: {
      changePage(page) {
        this.page = page;
      },
      openForgotPassword(on) {
        return ($event) => {
          on.click($event);
          this.page = 'forgotPassword';
        };
      },
      openConfirmPassword(on) {
        return ($event) => {
          on.click($event);
          this.page = 'confirmPassword';
        };
      },
      closeDialog() {
        this.dialog = false;
      },
    },
  };
