
  import Events from '@/events/Events';
  import EventBus from '@/events/EventBus';
  import {prepareServerSideValidationErrors} from '@/helper/FormHelper';
  export default {
    name: 'ApplicationForm',
    components: {
      CheckboxRichText: () => import('@/components/elements/CheckboxRichText'),
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
      comeFrom: {
        type: String,
        required: false,
        default: 'detailPage',
      },
    },
    data() {
      return {
        applicationData: {
          notes: null,
          terms: null,
          advertisingConsent: null,
          locations: [],
        },
        currentOrganization: {},
        organizationLink: '',
        organizationLinkText: '',
        organizationAvailable: false,
        disabled: false,
        locationItems: [],
      };
    },
    async fetch() {
      this.organizationLinkText =
        this.section.application_step_checkbox_organization_link;
      let organizationId = '';
      if (this.$cookies.get('organization_parameter')) {
        organizationId = this.$cookies.get('organization_parameter').id;
      } else if (
        this.$route.query.organization_id &&
        this.$route.query.mtm_source &&
        this.$route.query.mtm_medium &&
        this.$route.query.mtm_group
      ) {
        organizationId = this.$route.query.organization_id;
      }
      if (organizationId !== '') {
        await this.$store
          .dispatch('api/organization/getOrganization', {
            id: organizationId,
          })
          .then((result) => {
            this.currentOrganization = result;
          });
      }
      if (this.currentOrganization && this.organizationLinkText) {
        this.organizationLinkText = this.organizationLinkText.replace(
          /%company_name/g,
          this.currentOrganization.name
        );
      }
      this.organizationAvailableFunction();
    },
    watch: {
      section: async function (val) {
        if (val) {
          await this.insertDefaultText();
        }
      },
    },
    mounted() {
      this.setLocationsFunction();
    },
    methods: {
      setLocationsFunction() {
        this.locationItems = [...this.job.locations].sort((a, b) => {
          return a.city.localeCompare(b.city);
        });
        if (this.locationItems.length === 1) {
          this.applicationData.locations.push(this.locationItems[0].id);
        }
        //Check for query param and pre-select the location
        const locationId = this.$route.query.location_id;
        if (locationId) {
          const foundLocation = this.locationItems.find(
            (location) => location.id === locationId
          );
          if (foundLocation) {
            if (!this.applicationData.locations.includes(locationId)) {
              this.applicationData.locations.push(locationId);
            }
          }
        }
      },
      insertDefaultText() {
        if (!this.applicationData.notes) {
          this.applicationData.notes = this.section
            .application_step_default_text
            ? this.section.application_step_default_text
                .replace(/%job_activity/g, this.job.activities || '')
                .replace(/%job_name/g, this.job.name || '')
                .replace(/%job_period/g, this.job.period || '')
                .replace(/%user_name/g, '') || ''
            : '';
        }
      },
      async organizationAvailableFunction() {
        let cookieOrganizationId = '';
        let searchStatus = {};
        if (this.$cookies.get('organization_parameter')) {
          cookieOrganizationId = this.$cookies.get('organization_parameter').id;
        } else if (
          this.$route.query.organization_id &&
          this.$route.query.mtm_source &&
          this.$route.query.mtm_medium &&
          this.$route.query.mtm_group
        ) {
          cookieOrganizationId = this.$route.query.organization_id;
        }
        if (cookieOrganizationId !== '' && this.$auth.user.candidateProfile) {
          searchStatus = await this.$store.dispatch(
            'api/candidateProfile/getPBasisSearchStatus',
            {
              candidate_profile_id: this.$auth.user.candidateProfile.id,
              organization_id: cookieOrganizationId,
            }
          );
        }
        this.organizationAvailable =
          this.job.organization.id === cookieOrganizationId &&
          searchStatus.data === false;
      },
      async saveApplication() {
        this.disabled = true;
        const applyData = {
          notes: this.applicationData.notes,
          locationIds: this.applicationData.locations,
        };
        if (this.organizationLink) {
          applyData.assignToOrganization = this.$cookies.get(
            'organization_parameter'
          ).id;
        }
        this.$store
          .dispatch('api/application/postApplyOnJobPosting', {
            id: this.job.id,
            data: applyData,
          })
          .then(() => {
            this.terms = false;
            this.$emitWithClientData(
              this.comeFrom === 'AfterRegistration'
                ? Events.RegistrationBeforeApplicationStepFinished
                : Events.UpdateAndApplicationStepFinished,
              {
                jobPostingId: this.job.id,
                step: 'Application',
              }
            );
            this.$emitWithClientData(
              this.comeFrom === 'AfterRegistration'
                ? Events.RegistrationBeforeApplicationFinished
                : Events.UpdateAndApplicationFinished,
              {
                jobPostingId: this.job.id,
              }
            );

            this.$emit('application-is-finished');
            if (this.job.applicationUrl) {
              window.open(this.job.applicationUrl, '_blank');
              this.$notifications.showNotification({
                message: this.$t('job_card.application_url_message'),
                color: 'warning',
                applicationUrl: this.job.applicationUrl,
                timeout: -1,
                width: '400',
              });
            }
            EventBus.$emit('applied-on-job', this.job.id);
            const trackingObj = {
              jobPostingId: this.job.id,
              comeFrom: this.comeFrom,
              flow:
                this.comeFrom === 'AfterRegistration'
                  ? 'RegistrationBeforeApplication'
                  : 'UpdateAndApplication',
            };
            if (this.organizationLink) {
              trackingObj.assign_to_organization = this.$cookies.get(
                'organization_parameter'
              ).id;
            }
            this.$emitWithClientData(Events.JobAppliedTo, trackingObj);
            this.$auth.fetchUser().then(() => {
              this.$store.dispatch(
                'profileCompleteness/calculateProfileCompleteness'
              );
            });
          })
          .catch((e) => {
            switch (e.response.status) {
              case 422:
                if (
                  e.response.data.violations.some(
                    (violation) =>
                      violation.code === '0aafb198-80f0-c3c2-7109-1dd873ef5ba8'
                  )
                ) {
                  this.error = 'application.error.applications_limit';
                  break;
                }
                if (
                  e.response.data.violations.some(
                    (violation) =>
                      violation.code === '6d8b2f20-cc08-c59d-4ae4-5d4a9984ca8f'
                  )
                ) {
                  this.error = 'application.error.wrong_location';
                  break;
                }
                this.$refs.form.setErrors(
                  prepareServerSideValidationErrors(e.response)
                );
                break;
              case 400:
                this.error = null;
                switch (e.response.data['hydra:code']) {
                  case 100:
                    this.error = 'application.error.not_all_relevant_data';
                    break;
                  case 101:
                    this.error = 'application.error.already_applied';
                    break;
                  case 102:
                    this.error = 'application.error.not_verified';
                    break;
                  case 103:
                    this.error = 'application.error.job_posting_inactive';
                    break;
                }
                break;
              default:
                throw e;
            }
          });
        setTimeout(() => (this.disabled = false), 3000);
      },
    },
  };
