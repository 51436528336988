
  import Events from '@/events/Events';
  import {PreferredActivity} from '@/model/Profile';
  import ModelItems from '@/mixins/ModelItems';
  import {prepareServerSideValidationErrors} from '@/helper/FormHelper';
  export default {
    name: 'WorkPreferencesForm',
    mixins: [ModelItems],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
      comeFrom: {
        type: String,
        required: false,
        default: 'detailPage',
      },
    },
    data() {
      return {
        workPreferencesData: {
          preferredSalary: 0,
          preferredActivities: [],
        },
        preferredActivityItems: this.prepareModelItems(PreferredActivity),
        disabled: false,
      };
    },
    mounted() {
      const user = this.$auth.user.candidateProfile;
      this.workPreferencesData.preferredSalary = user.preferredSalary;
      if (
        user.preferredActivities &&
        Object.keys(user.preferredActivities).length > 0
      ) {
        Object.keys(user.preferredActivities).map((key) => {
          if (key != 'hydra:view') {
            this.workPreferencesData.preferredActivities.push(
              user.preferredActivities[key]
            );
          }
        });
      }
    },
    methods: {
      async saveWorkPreferencesData() {
        this.disabled = true;
        const user = this.$auth.user.candidateProfile;
        const data = {
          firstName: user.firstName ? user.firstName : '',
          lastName: user.lastName ? user.lastName : '',
          gender: user.gender,
          preferredSalary: this.workPreferencesData.preferredSalary,
          preferredActivities: this.workPreferencesData.preferredActivities,
        };
        await this.$store
          .dispatch('api/candidateProfile/patchProfile', {
            id: this.$auth.user.candidateProfile.id,
            data,
          })
          .then(() => {
            this.$emitWithClientData(
              this.comeFrom === 'AfterRegistration'
                ? Events.RegistrationBeforeApplicationStepFinished
                : Events.UpdateAndApplicationStepFinished,
              {
                jobPostingId: this.job.id,
                step: 'WorkPreferences',
              }
            );
            this.$emit('change-step');
            this.$auth.fetchUser().then(() => {
              this.$store.dispatch(
                'profileCompleteness/calculateProfileCompleteness'
              );
            });
          })
          .catch((e) => {
            if (!e.response || e.response.status !== 422) {
              throw e;
            }
            if (e.response.status === 422) {
              const violations = [];
              e.response.data.violations.map((violation) => {
                if (violation.code === '70997997-76fb-31fe-fe4a-941682389870') {
                  const vil = {
                    code: '70997997-76fb-31fe-fe4a-941682389870',
                    message: violation.message,
                    propertyPath: 'mobile',
                  };
                  violations.push(vil);
                }
              });
              e.response.data.violations = violations;
              this.$refs.form.setErrors(
                prepareServerSideValidationErrors(e.response)
              );
              this.$notifications.showNotification({
                message: violations[0].message,
                color: 'error',
                timeout: 5000,
              });
            } else {
              this.$notifications.showNotification({
                message: this.$t('form.notification.validation_error'),
                color: 'error',
                timeout: 5000,
              });
            }
          });
        setTimeout(() => (this.disabled = false), 3000);
      },
    },
  };
