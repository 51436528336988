import ApiArticle from '@/events/ApiTracking/Article';
import ApiGeneral from '@/events/ApiTracking/General';
import ApiJobPosting from '@/events/ApiTracking/JobPosting';
import ApiOrganization from '@/events/ApiTracking/Organization';
import ApiUserInteractions from '@/events/ApiTracking/UserInteractions';
import ApiWiki from '@/events/ApiTracking/Wiki';
import ApiEvent from '@/events/ApiTracking/Event';

import AppcastJobPostingAndApplication from '@/events/Appcast/JobPostingAndApplication';

import MatomoUserInteractions from '@/events/Matomo/UserInteractions';
import MatomoLogin from '@/events/Matomo/Login';
import MatomoRegistration from '@/events/Matomo/Registration';
import MatomoRegistrationOrLogin from '@/events/Matomo/RegistrationOrLogin';
import MatomoUpdateAndApplication from '@/events/Matomo/UpdateAndApplication';
import MatomoApplication from '@/events/Matomo/Application';
import MatomoJobApplication from '@/events/Matomo/JobApplication';
import MatomoRegistrationAndApplication from '@/events/Matomo/RegistrationAndApplication';
import MatomoRegistrationBeforeApplication from '@/events/Matomo/RegistrationBeforeApplication';
import MatomoOrganizationRegistration from '@/events/Matomo/OrganizationRegistration';
import MatomoThankYouPage from '@/events/Matomo/ThankYouPage';
import MatomoVideo from '@/events/Matomo/Video';

export default function (store, $auth, $matomo, $device) {
  // No tracking if impersonator
  if ($auth.loggedIn && $auth.user.impersonatorId) {
    return;
  }

  // ApiTracking
  ApiArticle(store);
  ApiGeneral(store);
  ApiJobPosting(store, $device);
  ApiOrganization(store);
  ApiUserInteractions(store);
  ApiWiki(store);
  ApiEvent(store);

  // Appcast
  AppcastJobPostingAndApplication();

  // Matomo
  if (process.client && $matomo) {
    MatomoUserInteractions($matomo);
    MatomoLogin($matomo);
    MatomoRegistration($matomo);
    MatomoRegistrationOrLogin($matomo);
    MatomoUpdateAndApplication($matomo);
    MatomoApplication($matomo);
    MatomoJobApplication($matomo);
    MatomoRegistrationAndApplication($matomo);
    MatomoRegistrationBeforeApplication($matomo);
    MatomoOrganizationRegistration($matomo);
    MatomoThankYouPage($matomo);
    MatomoVideo($matomo);
  }
}
