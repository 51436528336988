
  import Events from '@/events/Events';
  import AlertBox from '@/components/elements/AlertBox';

  export default {
    name: 'VerifyUserAlert',
    components: {
      AlertBox,
    },
    props: {
      text: {
        type: String,
        required: true,
      },
      eventType: {
        type: String,
        required: false,
        default: () => null,
      },
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    methods: {
      verifyUserProfile() {
        this.$store
          .dispatch('api/candidateAccount/validateCandidateAccount')
          .then(() => {
            this.$notifications.showNotification({
              message: this.$t('account.verify_user_email_sent'),
              color: 'success',
              timeout: 5000,
            });
            if (this.eventType && this.job) {
              this.$emitWithClientData(Events.AccountVerify, {
                type: this.eventType,
                jobPostingId: this.job.id,
              });
            }
          })
          .catch((e) => {
            if (e.response.status === 400) {
              this.$notifications.showNotification({
                message: this.$t('account.verify_user_email_already_sent'),
                color: 'error',
                timeout: 5000,
              });
              throw e;
            }
          });
      },
    },
  };
