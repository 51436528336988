
  import ScreenSize from '@/mixins/ScreenSize';
  import Events from '@/events/Events';

  export default {
    name: 'SectionHeadlineWithTextAndYoutubeVideo',
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      device: {
        type: String,
        required: false,
        default: 'mobile',
      },
    },
    serverCacheKey: function (props) {
      return (
        'section-headline-with-text-and-youtube-video-' +
        props.section.id +
        '-' +
        props.device
      );
    },
    data() {
      return {
        sizeOfVideoColumn: 0,
        isVideoLoaded: false,
        isLoaded: false,
        isFirstPlay: true,
        player: null,
        customHeight: null,
      };
    },
    computed: {
      customWidth() {
        const percentage = (this.sizeOfVideoColumn / 12) * 100;
        return this.$vuetify.breakpoint.width <=
          this.$vuetify.breakpoint.thresholds.sm
          ? '100%'
          : `${percentage}%`;
      },
      videoThumbnail() {
        if (
          this.section.yt_preview_type === 'thumbnail_image' &&
          this.section.yt_thumbnail_image
        ) {
          return this.section.yt_thumbnail_image.url;
        } else if (this.section.yt_preview_type === 'video_preview_image') {
          const videoId = this.extractYoutubeId(this.section.yt_video_url);
          return (
            `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` ||
            process.env.NUXT_ENV_FALLBACK_MEDIUM_HERO_IMAGE_STATIC
          );
        } else {
          return process.env.NUXT_ENV_FALLBACK_MEDIUM_HERO_IMAGE_STATIC;
        }
      },
      videoAltText() {
        return (
          this.section.yt_thumbnail_image?.alternativeText ||
          this.$t('videos.alternative_text_default')
        );
      },
    },
    mounted() {
      this.customHeight =
        this.section.video_height && this.isDesktopOrTablet
          ? this.section.video_height + 'px'
          : '100%';
      this.sizeOfVideoColumn = this.section.video_width
        ? Number(this.section.video_width.split('_')[1])
        : 6;

      if (
        this.section.yt_preview_type &&
        this.section.yt_preview_type === 'direct_video'
      ) {
        this.isVideoLoaded = true;
        this.loadYouTubeAPI().then(() => {
          this.initializeYouTubePlayer(false);
        });
      }

      this.isLoaded = true;
    },
    methods: {
      loadVideo() {
        if (!this.isVideoLoaded) {
          this.isVideoLoaded = true;
          this.loadYouTubeAPI().then(() => {
            this.initializeYouTubePlayer(true);
          });
        } else if (this.player) {
          this.player.playVideo();
        }
      },
      loadYouTubeAPI() {
        return new Promise((resolve) => {
          if (window.YT && window.YT.Player) {
            resolve();
          } else {
            const script = document.createElement('script');
            script.src = 'https://www.youtube.com/iframe_api';
            script.async = true;
            script.onload = () => {
              window.onYouTubeIframeAPIReady = () => {
                resolve();
              };
            };
            document.head.appendChild(script);
          }
        });
      },
      initializeYouTubePlayer(autoplay = false) {
        this.player = new window.YT.Player(this.$refs.youtubePlayerContainer, {
          videoId: this.extractYoutubeId(this.section.yt_video_url),
          height: this.customHeight,
          width: this.customWidth.replace('%', ''),
          events: {
            onReady: (event) => {
              if (autoplay) {
                event.target.playVideo();
              }
            },
            onStateChange: this.onPlayerStateChange,
          },
          playerVars: {
            autoplay: autoplay ? 1 : 0,
            rel: 0,
          },
        });
      },
      onPlayerStateChange(event) {
        if (event.data === window.YT.PlayerState.PLAYING && this.isFirstPlay) {
          this.$emitWithClientData(Events.VideoStarted, {
            url: this.section.yt_video_url,
          });
          this.isFirstPlay = false;
        }
      },
      extractYoutubeId(url) {
        const regExp = /^.*(youtu\.be\/|v\/|embed\/|watch\?v=|v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
      },
    },
  };
