
  import {fixedRouteSlugs} from '@/routes.config';
  import {prepareApiUrl} from '@/helper/UrlHelper';

  export default {
    name: 'DefaultAppBar',

    components: {
      SearchPopoverMenu: () => import('@/components/SearchPopoverMenu'),
      RegistrationOrLoginDialog: () =>
        import('@/components/candidate/RegistrationOrLoginDialog'),
      SocialIcons: () => import('@/components/SocialIcons'),
      LoggedUserNavigation: () => import('@/components/LoggedUserNavigation'),
    },
    props: {
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    /*
serverCacheKey(props) {
  // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
  if (props.loggedIn) {
    return false;
  }

  // Ansonsten generiere einen eindeutigen Cache-Schlüssel
  return 'appbar-' + '-' + props.device;
},
 */
    data: () => ({
      appBarNavigation: {
        id: 2,
        items: [
          {
            id: 22,
            internal_title: 'Jobs finden',
            main: {
              id: 83,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Jobs finden',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 9,
                InternalTitle: 'Joblisting Indexseite',
                slug: 'jobs',
                InternalDescription: null,
                Layout: null,
                Active: false,
                scope: 'promotionbasis_candidate',
                show_seo_content: null,
              },
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [],
          },
          {
            id: 5,
            internal_title: 'Jobs nach Kategorien',
            main: {
              id: 10,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Jobs nach Kategorien',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 15,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Promotionjobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 30,
                  InternalTitle: 'Landingpage Promotionjobs',
                  slug: 'landingpages-promotionjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 16,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Messejobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 42,
                  InternalTitle: 'Landingpage Messejobs',
                  slug: 'landingpages-messejobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 22,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Eventjobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 44,
                  InternalTitle: 'Landingpage Eventjobs',
                  slug: 'landingpages-eventjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 23,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Studentenjobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 32,
                  InternalTitle: 'Landingpage Studentenjobs',
                  slug: 'landingpages-studentenjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 24,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Rentnerjobs',
                icon: '',
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 46,
                  InternalTitle: 'Landingpage Rentnerjobs',
                  slug: 'landingpages-rentnerjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 49,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Schülerjobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 36,
                  InternalTitle: 'Landingpage Schülerjobs',
                  slug: 'landingpages-schuelerjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 108,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Nebenjobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 34,
                  InternalTitle: 'Landingpage Nebenjobs',
                  slug: 'landingpages-nebenjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 109,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Gastrojobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 40,
                  InternalTitle: 'Landingpage Gastrojobs',
                  slug: 'landingpages-gastrojobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 127,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Ferienjobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 38,
                  InternalTitle: 'Landingpage Ferienjobs',
                  slug: 'landingpages-ferienjobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 241,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Homeofficejobs',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: {
                  id: 59,
                  InternalTitle: 'Landingpage Homeoffice-Jobs',
                  slug: 'homeoffice-jobs',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: null,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
              {
                id: 50,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Alle Tätigkeiten im Überblick',
                icon: null,
                class: 'font-weight-bold',
                href: null,
                target: null,
                route: null,
                page: {
                  id: 3,
                  InternalTitle: 'Landingpage Tätigkeiten',
                  slug: 'landingpages-activities',
                  InternalDescription: null,
                  Layout: null,
                  Active: false,
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 23,
            internal_title: 'Jobs nach Städten',
            main: {
              id: 92,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Jobs nach Städten',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [
              {
                id: 93,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Berlin',
                icon: null,
                class: null,
                href: null,
                target: '_blank',
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 3,
                  title: 'Berlin',
                  slug: 'berlin',
                  latitude: 52.520008,
                  longitude: 13.404954,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: true,
                  name: 'Berlin',
                },
                title: null,
              },
              {
                id: 110,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Dresden',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 11,
                  title: 'Dresden',
                  slug: 'dresden',
                  latitude: 51.050407,
                  longitude: 13.737262,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: null,
                  name: 'Dresden',
                },
                title: null,
              },
              {
                id: 97,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Düsseldorf',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 14,
                  title: 'Düsseldorf',
                  slug: 'duesseldorf',
                  latitude: 51.233334,
                  longitude: 6.783333,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: null,
                  name: 'Düsseldorf',
                },
                title: null,
              },
              {
                id: 132,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Frankfurt',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 25,
                  title: 'Frankfurt am Main',
                  slug: 'frankfurt-am-main',
                  latitude: 50.1109221,
                  longitude: 8.6821267,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: null,
                  name: 'Frankfurt am Main',
                },
                title: null,
              },
              {
                id: 102,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Hamburg',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 2,
                  title: 'Hamburg',
                  slug: 'hamburg',
                  latitude: 53.551086,
                  longitude: 9.993682,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: true,
                  name: 'Hamburg',
                },
                title: null,
              },
              {
                id: 103,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Hannover',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 1,
                  title: 'Hannover',
                  slug: 'hannover',
                  latitude: 52.37655,
                  longitude: 9.741046,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: true,
                  name: 'Hannover',
                },
                title: null,
              },
              {
                id: 104,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Kiel',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 8,
                  title: 'Kiel',
                  slug: 'kiel',
                  latitude: 54.323334,
                  longitude: 10.139444,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: null,
                  name: 'Kiel',
                },
                title: null,
              },
              {
                id: 133,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Köln',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 28,
                  title: 'Köln',
                  slug: 'koeln',
                  latitude: 50.937531,
                  longitude: 6.9602786,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: null,
                  name: 'Köln',
                },
                title: null,
              },
              {
                id: 105,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in München',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 4,
                  title: 'München',
                  slug: 'muenchen',
                  latitude: 48.137154,
                  longitude: 11.576124,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: false,
                  name: 'München',
                },
                title: null,
              },
              {
                id: 106,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Jobs in Stuttgart',
                icon: null,
                class: null,
                href: null,
                target: null,
                route: null,
                page: null,
                article: null,
                activity: null,
                city: {
                  id: 6,
                  title: 'Stuttgart',
                  slug: 'stuttgart',
                  latitude: 48.783333,
                  longitude: 9.183333,
                  distance: 50000,
                  scope: 'promotionbasis',
                  teasered: null,
                  name: 'Stuttgart',
                },
                title: null,
              },
              {
                id: 107,
                Text: null,
                Url: null,
                Target: null,
                Title: null,
                Icon: null,
                text: 'Alle Städte im Überblick',
                icon: null,
                class: 'font-weight-bold',
                href: null,
                target: null,
                route: null,
                page: {
                  id: 51,
                  InternalTitle: 'Landingpage Städte',
                  slug: 'landingpages-cities',
                  InternalDescription: null,
                  Layout: null,
                  Active: null,
                  views: '169',
                  scope: 'promotionbasis_candidate',
                  show_seo_content: null,
                },
                article: null,
                activity: null,
                city: null,
                title: null,
              },
            ],
          },
          {
            id: 24,
            internal_title: 'Tipps & FAQ',
            main: {
              id: 100,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Tipps & FAQ',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 1,
                InternalTitle: 'Artikel-Übersicht',
                slug: 'articles',
                InternalDescription: null,
                Layout: null,
                Active: false,
                views: '1660',
                scope: 'promotionbasis_candidate',
                show_seo_content: true,
              },
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [],
          },
          {
            id: 28,
            internal_title: 'Agenturen finden',
            main: {
              id: 171,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Agenturen finden',
              icon: null,
              class: null,
              href: null,
              target: null,
              route: null,
              page: {
                id: 71,
                InternalTitle: 'Jobanbieter-Listing Indexseite',
                slug: 'organizations',
                InternalDescription: null,
                Layout: null,
                Active: null,
                views: null,
                scope: 'promotionbasis_candidate',
                show_seo_content: null,
              },
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            items: [],
          },
        ],
      },
      overlay: false,
      menu: false,
      drawer: false,
      menuItems: [],
      registerMenuModel: false,
      logoUrl: require(process.env.NUXT_ENV_FALLBACK_LOGO),
      iconColor: 'primaryYellow--text',
      menuItemsOutsideDropdown: [],
      menuItemsInDropdown: [],
      menuItemNumber: 5,
      debouncedResizeHandler: null,
    }),
    computed: {
      profileImagePortrait() {
        return !!(
          this.$auth.loggedIn &&
          this.$auth.user.candidateProfile.profileImagePortrait
        );
      },
      profileImageUrl() {
        return prepareApiUrl(
          process.env.NUXT_ENV_API_PROFILE_IMAGES_FILE_ENDPOINT,
          {
            routeParameters: {
              id: this.$auth.user.candidateProfile.profileImagePortrait.id,
            },
            getParameters: {filter: 'thumbnail'},
          }
        );
      },
    },
    watch: {
      drawer: function (val) {
        if (val) {
          document.documentElement.style.overflow = 'hidden';
        } else {
          document.documentElement.style.overflow = 'auto';
        }
      },
    },
    async created() {
      /* Json für Navigation zunächst statisch und nicht mehr dynamisch aus Strapi!
      const appBarNavigation = await this.$cachedStrapi
      .find('app-bar-navigation')
      .catch((e) => {
        if (e.statusCode !== 404) {
          throw e;
        }
      });
     */

      if (
        this.appBarNavigation.items &&
        this.appBarNavigation.items.length > 0
      ) {
        this.appBarNavigation.items.forEach((menuItem) => {
          // prepare main menu item
          const mainMenuItem = this.prepareMenuItem(menuItem.main);
          mainMenuItem.items = [];

          // prepare menu sub items
          if (menuItem.items && menuItem.items.length > 0) {
            menuItem.items.forEach((subItem) => {
              const subMenuItem = this.prepareMenuItem(subItem);
              mainMenuItem.items.push(subMenuItem);
            });
          }
          this.menuItems.push(mainMenuItem);
          this.menuItemsOutsideDropdown = this.menuItems;
          this.menuItemNumber = this.menuItemsOutsideDropdown.length;
        });
      }
    },
    mounted() {
      document.documentElement.style.overflow = 'auto';
      this.calculateMainMenuSize();
      import('lodash/function').then(({debounce}) => {
        // Debounce-Funktion für das Resize-Event
        this.debouncedResizeHandler = debounce(() => {
          this.calculateMainMenuSize();
        }, 200); // 200 Millisekunden Debounce-Zeit

        window.addEventListener('resize', this.debouncedResizeHandler);
      });
    },
    beforeDestroy() {
      if (this.debouncedResizeHandler)
        window.removeEventListener('resize', this.debouncedResizeHandler);
    },
    methods: {
      checkClickOption(e, item) {
        if (item.items.length > 0) {
          e.stopPropagation();
        }
      },
      calculateMainMenuSize() {
        if (window.innerWidth >= 1360) {
          this.menuItemNumber = 5;
        } else if (window.innerWidth >= 1100 && window.innerWidth < 1360) {
          this.menuItemNumber = 3;
        } else if (window.innerWidth < 1100) {
          this.menuItemNumber = 2;
        }
        this.setupMenu();
      },
      setupMenu() {
        this.menuItemsOutsideDropdown = this.menuItems.slice(
          0,
          this.menuItemNumber
        );
        this.menuItemsInDropdown = this.menuItems.slice(this.menuItemNumber);
      },
      togglePopupMenu(event) {
        this.menu = event.menu;
        this.overlay = event.overlay;
      },
      prepareMenuItem(item) {
        const menuItem = {
          name: item.text,
        };

        // prepare route for menu item
        let route = null;
        if (item.route) {
          route = item.route;
        } else if (item.page && item.page.slug) {
          if (fixedRouteSlugs.includes(item.page.slug)) {
            route = {
              name: item.page.slug,
            };
          } else {
            route = {
              name: 'slug',
              params: {slug: item.page.slug},
            };
          }
        } else if (item.article) {
          route = {
            name: 'articles-category-article',
            params: {
              category: item.article.article_category.slug,
              article: item.article.slug,
            },
          };
        } else if (item.activity) {
          route = {
            name: 'landingpages-activities-activity',
            params: {
              activity: item.activity.slug,
            },
          };
        } else if (item.city) {
          route = {
            name: 'landingpages-cities-city',
            params: {
              city: item.city.slug,
            },
          };
        } else if (item.href) {
          menuItem.href = item.href;
          menuItem.target = item.target;
        }

        menuItem.route = route;

        menuItem.class = item.class;
        menuItem.icon = item.icon;
        menuItem.title = item.title;

        return menuItem;
      },
    },
  };
